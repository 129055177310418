import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import logo from '@fv/client-assets/freightview.svg'
import { Icon } from '@fv/client-components'
import { apiUri, coreApiRootUrl } from '@fv/client-core'
import { fetchAdminContext } from '@/hooks/useAdminContext'

export const Login = () => {
  const { registrationToken } = useParams()

  useEffect(() => {
    const checkContext = async () => {
      try {
        const result = await fetchAdminContext()

        if (result.sessionId) {
          window.location.href = '/'
        }
      } catch (e) {
        // Nothing
      }
    }

    checkContext()
  }, [])

  if (registrationToken) {
    return (
      <h1>
        <Icon icon="spinner" />
      </h1>
    )
  }

  return (
    <div>
      <header className="relative flex items-center justify-center gap-4 border-b border-[#e3e3e3] bg-[#f9f9f9] px-8 py-4 text-center">
        <img
          src={logo}
          alt="Freightview"
          className="apect-auto inline-block w-16"
        />
        <span className="b600:hidden text-lg">Log in to Freightview Admin</span>
      </header>

      <div className="flex justify-center mt-10">
        <a
          className="btn-primary btn btn-outline"
          href={`${apiUri}/auth/login/google`}
        >
          <Icon icon="google" />
          Login
        </a>
      </div>
    </div>
  )
}
