import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { Login } from './components/auth/Login'
import { AuthenticatedApp } from './components/AuthenticatedApp'
import ErrorBoundary from './components/layout/ErrorBoundary'

// Add plugins for use in other components
dayjs.extend(relativeTime)
dayjs.extend(utc)

export const App = () => {
  return (
    <div className="flex min-h-screen flex-col">
      <ErrorBoundary>
        <BrowserRouter>
          <Routes>
            <Route path="login" element={<Login />} />

            <Route path="*" element={<AuthenticatedApp />} />
          </Routes>
        </BrowserRouter>
      </ErrorBoundary>
    </div>
  )
}
