import { vEmail, vObject, vOutput, vPipe, vRegex, vString } from '../validation'

export const forgotPasswordSchema = vObject({
  email: vEmail,
})
export type ForgotPasswordModel = vOutput<typeof forgotPasswordSchema>

export interface ForgotPasswordResponse {
  status: 'ok' | 'error'
  message?: string
}
export const passwordRegex = new RegExp(
  /^(?=.*[A-Z])(?=.*[!@#$&*\-_%*)(+><"'\][}{./?}])(?=.*[0-9])(?=.*[a-z]).{8,}$/,
)

export const passwordRequirementsMsg =
  'Passwords must be at least 8 characters long, have an upper and lowercase character, a digit and a special character.'

export const resetPasswordSchema = vObject({
  password: vPipe(vString(), vRegex(passwordRegex)),
  confirmPassword: vString(),
  token: vString(),
})
export type ResetPasswordModel = vOutput<typeof resetPasswordSchema>

export interface ResetPasswordResponse {
  status: 'ok' | 'error'
  message?: string
}
