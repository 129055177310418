import PropTypes from 'prop-types'

import LoadingPage from '../components/layout/LoadingPage'
import { useAdminContext } from '../hooks/useAdminContext'

// Here we will manage site-wide data like user context and active opportunities.
const SiteProvider = ({ children }) => {
  const contextQuery = useAdminContext()

  if (!contextQuery.data) return <LoadingPage />

  return children
}

SiteProvider.propTypes = {
  children: PropTypes.any,
}

export default SiteProvider
