import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faGoogle, faUps } from '@fortawesome/free-brands-svg-icons'
// import { faUserGroup } from '@fortawesome/pro-light-svg-icons'
import {
  faAddressBook,
  faAnalytics,
  faAngleDown,
  faAngleUp,
  faArchive,
  faArrowDown,
  faArrowDown19,
  faArrowDownAZ,
  faArrowDownToLine,
  faArrowLeft,
  faArrowRight,
  faArrowRotateRight,
  faArrowsAltV,
  faArrowsFromLine,
  faArrowsUpDown,
  faArrowToLeft,
  faArrowToRight,
  faArrowTurnDownRight,
  faArrowUp,
  faAt,
  faBackspace,
  faBadgeCheck,
  faBadgeDollar,
  faBallotCheck,
  faBan,
  faBars,
  faBell,
  faBellOn,
  faBook,
  faBox,
  faBoxes,
  faBroadcastTower,
  faBuilding,
  faBullseyeArrow,
  faCalculator,
  faCalendar,
  faCalendarAlt,
  faCalendarDay,
  faCaretCircleDown,
  faCaretCircleUp,
  faChartSimple,
  faCheck,
  faCheckCircle,
  faCheckDouble,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCity,
  faClipboard,
  faClock,
  faClone,
  faCloudDownload,
  faCloudUpload,
  faCloudUploadAlt,
  faCode,
  faCog,
  faColumns3,
  faCommentAlt,
  faCommentAltDollar,
  faCommentAltDots,
  faCommentAltLines,
  faCommentAltMinus,
  faCommentAltSlash,
  faCreditCardBlank,
  faDash,
  faDollarSign,
  faDolly,
  faDonate,
  faDotCircle,
  faDownload,
  faEdit,
  faEllipsisH,
  faEllipsisV,
  faEnvelope,
  faEnvelopeOpen,
  faEnvelopes,
  faEraser,
  faExchange,
  faExclamationTriangle,
  faExternalLinkAlt,
  faEye,
  faFemale,
  faFile,
  faFileAlt,
  faFileContract,
  faFileExport,
  faFileInvoiceDollar,
  faFileMinus,
  faFilePlus,
  faFilter,
  faFlask,
  faForklift,
  faGasPump,
  faGlobe,
  faGlobeAmericas,
  faHammer,
  faHandPaper,
  faHandPointer,
  faHashtag,
  faHistory,
  faHome,
  faIdCardAlt,
  faIndustryAlt,
  faInfoCircle,
  faKey,
  faLayerGroup,
  faLink,
  faList,
  faListUl,
  faLocationArrow,
  faLock,
  faMale,
  faMapMarker,
  faMapSigns,
  faMarker,
  faMerge,
  faMessageDots,
  faMessages,
  faMicrochipAi,
  faMinus,
  faMoneyCheckAlt,
  faMoneyCheckEditAlt,
  faPaintBrush,
  faPaperPlane,
  faPen,
  faPenAlt,
  faPercent,
  faPhone,
  faPlus,
  faPowerOff,
  faPrint,
  faQuestionCircle,
  faQuoteLeft,
  faRadiationAlt,
  faRampLoading,
  faRandom,
  faRedo,
  faRepeatAlt,
  faRocketLaunch,
  faRoute,
  faRouteInterstate,
  faRuler,
  faSave,
  faSearch,
  faServer,
  faShare,
  faShieldCheck,
  faShippingFast,
  faShippingTimed,
  faSolarSystem,
  faSortAlt,
  faSortCircleUp,
  faSpinner,
  faSquare,
  faSquareArrowDown,
  faSquareArrowUp,
  faSquareArrowUpRight,
  faSquareFull,
  faSquareMinus,
  faStarSharp,
  faStepForward,
  faStickyNote,
  faStoreAlt,
  faSync,
  faTable,
  faTags,
  faTally,
  faThumbsUp,
  faThumbtack,
  faTicket,
  faTimes,
  faToggleOn,
  faToiletPaperUnder,
  faTrash,
  faTrashRestoreAlt,
  faTree,
  faTriangle,
  faTrophy,
  faTruck,
  faTruckFast,
  faTruckMoving,
  faTruckRampBox,
  faUndo,
  faUpload,
  faUsdCircle,
  faUsdSquare,
  faUser,
  faUserCheck,
  faUserCircle,
  faUsers,
  faUserShield,
  faWarehouse,
  faWarehouseAlt,
  faWeightHanging,
} from '@fortawesome/pro-regular-svg-icons'
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome'
import { ForwardedRef, forwardRef, memo } from 'react'

import { PropsWithClassName } from '@fv/client-types'

const faIcons = {
  'address-book': faAddressBook,
  'angle-down': faAngleDown,
  'angle-up': faAngleUp,
  'arrow-down': faArrowDown,
  'arrow-down-1-9': faArrowDown19,
  'arrow-down-a-z': faArrowDownAZ,
  'arrow-down-to-line': faArrowDownToLine,
  'arrow-turn-down-right': faArrowTurnDownRight,
  'arrow-left': faArrowLeft,
  'arrow-right': faArrowRight,
  'arrow-rotate-right': faArrowRotateRight,
  'arrow-up': faArrowUp,
  'arrow-to-left': faArrowToLeft,
  'arrow-to-right': faArrowToRight,
  'arrows-alt-v': faArrowsAltV,
  'arrows-from-line': faArrowsFromLine,
  'arrows-up-down': faArrowsUpDown,
  'badge-check': faBadgeCheck,
  'badge-dollar': faBadgeDollar,
  'ballot-check': faBallotCheck,
  'bell-on': faBellOn,
  'bullseye-arrow': faBullseyeArrow,
  'calendar-alt': faCalendarAlt,
  'calendar-day': faCalendarDay,
  'caret-circle-down': faCaretCircleDown,
  'caret-circle-up': faCaretCircleUp,
  'chart-simple': faChartSimple,
  'check-circle': faCheckCircle,
  'check-double': faCheckDouble,
  'chevron-down': faChevronDown,
  'chevron-left': faChevronLeft,
  'chevron-right': faChevronRight,
  'chevron-up': faChevronUp,
  'cloud-download': faCloudDownload,
  'cloud-upload': faCloudUpload,
  'cloud-upload-alt': faCloudUploadAlt,
  'comment-alt-dollar': faCommentAltDollar,
  'comment-alt-dots': faCommentAltDots,
  'comment-alt-lines': faCommentAltLines,
  'comment-alt-minus': faCommentAltMinus,
  'comment-alt-slash': faCommentAltSlash,
  'comment-alt': faCommentAlt,
  'columns-3': faColumns3,
  'credit-card-blank': faCreditCardBlank,
  'dollar-sign': faDollarSign,
  'dot-circle': faDotCircle,
  'ellipsis-h': faEllipsisH,
  'ellipsis-v': faEllipsisV,
  'envelope-open': faEnvelopeOpen,
  'exclamation-triangle': faExclamationTriangle,
  'external-link-alt': faExternalLinkAlt,
  'file-alt': faFileAlt,
  'file-contract': faFileContract,
  'file-export': faFileExport,
  'file-invoice-dollar': faFileInvoiceDollar,
  'file-minus': faFileMinus,
  'file-plus': faFilePlus,
  'gas-pump': faGasPump,
  'globe-americas': faGlobeAmericas,
  'hand-paper': faHandPaper,
  'hand-pointer': faHandPointer,
  'id-card-alt': faIdCardAlt,
  'industry-alt': faIndustryAlt,
  'info-circle': faInfoCircle,
  'layer-group': faLayerGroup,
  'list-ul': faListUl,
  'location-arrow': faLocationArrow,
  'map-marker': faMapMarker,
  'map-signs': faMapSigns,
  merge: faMerge,
  'message-dots': faMessageDots,
  'micro-chip-ai': faMicrochipAi,
  'money-check-alt': faMoneyCheckAlt,
  'money-check-edit-alt': faMoneyCheckEditAlt,
  'paint-brush': faPaintBrush,
  'paper-plane': faPaperPlane,
  'pen-alt': faPenAlt,
  'power-off': faPowerOff,
  'question-circle': faQuestionCircle,
  'quote-left': faQuoteLeft,
  'radiation-alt': faRadiationAlt,
  'ramp-loading': faRampLoading,
  'repeat-alt': faRepeatAlt,
  'route-interstate': faRouteInterstate,
  'shield-check': faShieldCheck,
  'shipping-fast': faShippingFast,
  'shipping-timed': faShippingTimed,
  'solar-system': faSolarSystem,
  'sort-alt': faSortAlt,
  'sort-circle-up': faSortCircleUp,
  'square-arrow-up-right': faSquareArrowUpRight,
  'square-full': faSquareFull,
  'square-minus': faSquareMinus,
  'square-arrow-up': faSquareArrowUp,
  'square-arrow-down': faSquareArrowDown,
  'star-sharp': faStarSharp,
  'step-forward': faStepForward,
  'sticky-note': faStickyNote,
  'store-alt': faStoreAlt,
  'thumbs-up': faThumbsUp,
  'toggle-on': faToggleOn,
  'toilet-paper-under': faToiletPaperUnder,
  'broadcast-tower': faBroadcastTower,
  'trash-restore-alt': faTrashRestoreAlt,
  'truck-moving': faTruckMoving,
  'truck-fast': faTruckFast,
  'truck-ramp-box': faTruckRampBox,
  'usd-circle': faUsdCircle,
  'usd-square': faUsdSquare,
  'user-check': faUserCheck,
  'user-circle': faUserCircle,
  'user-shield': faUserShield,
  'warehouse-alt': faWarehouseAlt,
  'weight-hanging': faWeightHanging,
  analytics: faAnalytics,
  archive: faArchive,
  at: faAt,
  backspace: faBackspace,
  ban: faBan,
  bars: faBars,
  bell: faBell,
  book: faBook,
  box: faBox,
  boxes: faBoxes,
  building: faBuilding,
  calculator: faCalculator,
  calendar: faCalendar,
  check: faCheck,
  circle: faCircle,
  city: faCity,
  clipboard: faClipboard,
  clock: faClock,
  clone: faClone,
  code: faCode,
  cog: faCog,
  dash: faDash,
  dolly: faDolly,
  donate: faDonate,
  download: faDownload,
  edit: faEdit,
  envelope: faEnvelope,
  envelopes: faEnvelopes,
  eraser: faEraser,
  exchange: faExchange,
  eye: faEye,
  female: faFemale,
  file: faFile,
  filter: faFilter,
  forklift: faForklift,
  globe: faGlobe,
  hammer: faHammer,
  hashtag: faHashtag,
  history: faHistory,
  home: faHome,
  key: faKey,
  link: faLink,
  list: faList,
  lock: faLock,
  male: faMale,
  marker: faMarker,
  messages: faMessages,
  minus: faMinus,
  pen: faPen,
  percent: faPercent,
  phone: faPhone,
  plus: faPlus,
  print: faPrint,
  random: faRandom,
  redo: faRedo,
  rocket: faRocketLaunch,
  route: faRoute,
  ruler: faRuler,
  save: faSave,
  search: faSearch,
  server: faServer,
  share: faShare,
  spinner: faSpinner,
  square: faSquare,
  sync: faSync,
  table: faTable,
  tags: faTags,
  tally: faTally,
  thumbtack: faThumbtack,
  ticket: faTicket,
  times: faTimes,
  trash: faTrash,
  tree: faTree,
  triangle: faTriangle,
  trophy: faTrophy,
  truck: faTruck,
  undo: faUndo,
  upload: faUpload,
  ups: faUps,
  user: faUser,
  users: faUsers,
  warehouse: faWarehouse,
  experiment: faFlask,
  google: faGoogle,
}

export type IconName = keyof typeof faIcons

export type IconProps = PropsWithClassName<
  Omit<FontAwesomeIconProps, 'icon'> & {
    icon: IconName
  }
>

const FaIcon = memo(FontAwesomeIcon)

const IconRef = forwardRef(
  ({ icon, ...props }: IconProps, ref?: ForwardedRef<SVGElement>) => {
    if (!faIcons[icon]) {
      console.warn(`Icon '${icon}' has not been defined.`)
      icon = 'spinner'
    }

    const faIcon = faIcons[icon] as IconProp

    return (
      <FaIcon
        {...props}
        forwardedRef={ref}
        icon={faIcon}
        spin={icon === 'spinner' || icon === 'sync' || props.spin}
      />
    )
  },
)

export const Icon = memo(IconRef)
