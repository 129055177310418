import { useMutation } from '@tanstack/react-query'

import { type ApplicationType } from '@fv/models'
import { type ViewAsRequest, type ViewAsResponse } from '@fv/models/admin'
import { adminFetch } from '@/utils'

const postViewAs = (body: ViewAsRequest) =>
  adminFetch<ViewAsResponse>(`/register/view-as`, {
    method: 'POST',
    body,
  })
export const useViewAsUser = (app: ApplicationType) => {
  return useMutation(({ userId }: Pick<ViewAsRequest, 'userId'>) =>
    postViewAs({
      userId,
      application: app,
    }),
  )
}
