import { useQueryClient } from '@tanstack/react-query'
import { type PropsWithChildren } from 'react'

import { type PropsWithClassName } from '@fv/client-types'
import { usersQueryKeys } from '@/hooks/useFetchUsers'

import { carrierUri } from '../../constants'
import { ViewAsLink } from '../users/ViewAsLink'

export type ViewAsProps = {
  userId: string
  redirect?: string
}

export const ViewAsCarrierLink = (
  props: PropsWithClassName<PropsWithChildren<ViewAsProps>>,
) => {
  const queryClient = useQueryClient()

  const handleViewAs = (token: string) => {
    let uri = `${carrierUri}/login/${token}`

    if (props.redirect) {
      uri += `?redirect=${encodeURIComponent(props.redirect)}`
    }

    queryClient.invalidateQueries(usersQueryKeys.all)

    window.open(uri, 'view-as-carrier-web')
  }

  return (
    <ViewAsLink app="carrier-web" userId={props.userId} onViewAs={handleViewAs}>
      {props.children}
    </ViewAsLink>
  )
}
