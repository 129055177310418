import { forward, partialCheck } from 'valibot'

import {
  vCheck,
  vEmail,
  vObject,
  vOptional,
  vOutput,
  vPipe,
  vRegex,
  vString,
} from '../validation'
import { passwordRegex, passwordRequirementsMsg } from './password'

const unableMsg = 'Unable to register at this time, please try agian later.'
const registrationBlacklist = [
  /kravify\.com/i,
  /oastify\.com/i,
  /burpcollaborator/i,
]

const googleTrackingSchema = vObject({
  matchtype: vOptional(vString()),
  network: vOptional(vString()),
  device: vOptional(vString()),
  creative: vOptional(vString()),
  keyword: vOptional(vString()),
  adposition: vOptional(vString()),
  source: vOptional(vString()),
  term: vOptional(vString()),
  medium: vOptional(vString()),
})

export type GoogleTracking = vOutput<typeof googleTrackingSchema>

export const signUpSchema = vPipe(
  vObject({
    name: vPipe(
      vOptional(vString()),
      vCheck(i => !i, unableMsg),
    ),
    email: vPipe(
      vString(),
      vEmail,
      vCheck(v => !registrationBlacklist.some(b => b.test(v)), unableMsg),
    ),
    password: vPipe(vString(), vRegex(passwordRegex, passwordRequirementsMsg)),
    confirmPassword: vString(),
    campaign: vOptional(vString()),
    googleTracking: vOptional(googleTrackingSchema),
  }),
  forward(
    partialCheck(
      [['password'], ['confirmPassword']],
      input => input.password === input.confirmPassword,
      'Passwords must match',
    ),
    ['confirmPassword'],
  ),
)

export type SignupDto = vOutput<typeof signUpSchema>

export interface SignupResponse {
  success: boolean
  messages?: string[]
}
