import { type PropsWithChildren } from 'react'
import toast from 'react-hot-toast'

import { type ApplicationType } from '@fv/models'
import { AdminLink } from '@/components/shared/AdminLink'

import { NO_EXPORT_ATTR } from '../reports/helpers'
import { useViewAsUser } from './mutations'

type Props = PropsWithChildren<{
  app: ApplicationType
  userId: string
  onViewAs: (token: string) => void
  onClick?: () => void
}>
export const ViewAsLink = ({ app, userId, children, onViewAs }: Props) => {
  const viewAs = useViewAsUser(app)
  const handleClick = async () => {
    const { token } = await viewAs.mutateAsync({ userId })
    if (!token) {
      toast.error('Error viewing as user')
      return
    }
    onViewAs(token)
  }
  return (
    <AdminLink {...{ [NO_EXPORT_ATTR]: true }} onClick={handleClick}>
      {children || 'View as'}
    </AdminLink>
  )
}
