import { vObject, vOutput, vString } from '../validation'

export const verifyTokenSchema = vObject({
  token: vString(),
})
export type VerifyTokenDto = vOutput<typeof verifyTokenSchema>

export interface VerifyTokenResponse {
  success: boolean
}
