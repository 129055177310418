import {
  vBoolean,
  vEmail,
  vNonEmptyString,
  vObject,
  vOneOf,
  vOptional,
  vOutput,
  vString,
} from '../validation'

const accountUserProfileBase = vObject({
  email: vEmail,
  firstName: vNonEmptyString(),
  lastName: vNonEmptyString(),
  phoneNumber: vString(),
  title: vString(),
  tac: vOptional(vBoolean()),
})

const onboardingProfileSchema = vObject({
  ...accountUserProfileBase.entries,
  company: vString(),
})
const userSetupProfileSchema = vObject({
  ...accountUserProfileBase.entries,
  password: vString(),
})

export const accountUserProfileUpdateSchema = vOneOf([
  // order of these is important
  userSetupProfileSchema,
  onboardingProfileSchema,
  accountUserProfileBase,
])

export type OnboardingProfileDto = vOutput<typeof onboardingProfileSchema>
type UserSetupProfileDto = vOutput<typeof userSetupProfileSchema>
export type AccountUserProfileUpdateDto = vOutput<
  typeof accountUserProfileUpdateSchema
>

export const isOnboardingProfileDto = (
  v: AccountUserProfileUpdateDto,
): v is OnboardingProfileDto => !!(v as OnboardingProfileDto).company

export const isUserSetupProfileDto = (
  v: AccountUserProfileUpdateDto,
): v is UserSetupProfileDto => !!(v as UserSetupProfileDto).password
