import {
  apiUri,
  appName,
  appVersion,
  getEnvValue,
  shipperUri,
  VITE_ENV,
} from '@fv/client-core'

export { apiUri, appName, appVersion, shipperUri }

export const carrierAppName = 'carrier-web'
export const carrierUri = getEnvValue('VITE_CARRIER_URI')
export const chrobinsonID = '5304f480259b15a354000028'
export const defaultPageSize = 20
export const environment = VITE_ENV
export const maxFileSize = 52428800 // 50 MB
export const siteTitle = 'Freightview Admin Tools'
