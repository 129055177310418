import { useQuery } from '@tanstack/react-query'

import { logoutSession } from '@fv/client-core'

import { apiUri } from '../constants'
import { adminFetch } from '../utils'

type ContextDTO = {
  liveEventsUri?: string
  sessionId: string
}

export async function fetchAdminContext() {
  return adminFetch<ContextDTO>('/context')
}

export function useAdminContext() {
  return useQuery(['adminContext'], fetchAdminContext, {
    enabled: true,
    onError: (e: any) => {
      if (e?.status === 401) {
        logoutSession(null, apiUri)
      }
    },
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    retry: false,
  })
}
