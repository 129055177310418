import { ObjectId } from '@freightview/object-id'

import { vObject, vObjectId, vOutput } from '../validation'
import { ApplicationType } from './common'

export const userTypes = ['admin', 'carrier', 'shipper', 'vendor'] as const
export type UserType = (typeof userTypes)[number]

export interface UserProfile {
  firstName: string | null
  lastName: string | null
  role: string | null
}

export type UserSession = {
  active?: boolean
  lastUsed: Date
  created: Date
  userAgent?: string
  ip?: string
  application: ApplicationType
  _id: ObjectId
}

export type User = {
  _id: ObjectId
  created: Date
  carrierId?: ObjectId
  email: string
  isVerified: boolean
  sessions?: UserSession[]
  type?: UserType
  profile?: UserProfile
  accounts?: string[]
}

export type AdminCarrierUser = Omit<User, 'accounts'> & {
  activeRegistration?: {
    token: string
    expires: Date
  }

  accounts: Array<{ _id: ObjectId; name: string }>

  carrier: {
    _id: ObjectId
    name: string
    code?: string
  }
}

export const changeUserCarrierSchema = vObject({
  carrierId: vObjectId(),
})

export type ChangeUserCarrierDTO = vOutput<typeof changeUserCarrierSchema>
