import { useQueryClient } from '@tanstack/react-query'
import maxBy from 'lodash/maxBy'
import orderBy from 'lodash/orderBy'

import { FvDate } from '@fv/client-components'
import { type AdminCarrierUser } from '@fv/models'
import { type DTO } from '@fv/models/core'
import { AdminButton } from '@/components/shared/AdminButton'
import { AdminLink } from '@/components/shared/AdminLink'
import { AdminTable } from '@/components/shared/AdminTable'
import { CopyButton } from '@/components/shared/CopyButton'
import { carrierUri } from '@/constants'
import { usersQueryKeys } from '@/hooks/useFetchUsers'

import { NO_EXPORT_ATTR } from '../reports/helpers'
import { ChangeCarrierSliderButton } from './ChangeCarrierSliderButton'
import { useVerifyUser } from './mutations'
import { SessionSliderButton } from './SessionSliderButton'
import { ViewAsCarrierLink } from './ViewAsCarrierLink'

type Props = {
  users: DTO<AdminCarrierUser>[]
  showCarrierCol?: boolean
  sortable?: boolean
}

export const CarrierUsersTable = ({
  users,
  showCarrierCol,
  sortable,
}: Props) => {
  const verifyUser = useVerifyUser()
  const queryclient = useQueryClient()

  const handleVerify = async (userId: string) => {
    await verifyUser.mutateAsync(userId)
    queryclient.invalidateQueries(usersQueryKeys.all)
  }

  if (!users?.length) return null

  return (
    <>
      <AdminTable
        rowKey={u => u._id}
        rowClassName={() => 'leading-snug [&>td]:align-top'}
        data={users}
        disableSort={!sortable}
        columns={[
          {
            key: 'isVerified',
            label: '',
            render: user => (
              <div
                className="w-[155px] flex flex-col gap-2"
                {...{ [NO_EXPORT_ATTR]: true }}
              >
                {!user.isVerified && (
                  <div className="flex">
                    <AdminButton
                      onClick={() => handleVerify(user._id)}
                      className="btn-success btn-xs"
                      icon="user-check"
                      loading={verifyUser.isLoading}
                    >
                      Verify
                    </AdminButton>
                  </div>
                )}

                <div className="flex">
                  <ChangeCarrierSliderButton user={user} />
                </div>
              </div>
            ),
          },
          {
            key: 'email',
            label: 'Email',
            render: user => (
              <div className="flex flex-col">
                {user.email}

                <small {...{ [NO_EXPORT_ATTR]: true }}>
                  {user.profile?.firstName} {user.profile?.lastName}{' '}
                  {user.profile?.role ? `| ${user.profile.role}` : ''}
                </small>
                <small {...{ [NO_EXPORT_ATTR]: true }}>{user._id}</small>
                <div className="flex justify-between">
                  <ViewAsCarrierLink className="text-xs" userId={user._id}>
                    View as
                  </ViewAsCarrierLink>
                  {!!user.activeRegistration && (
                    <div
                      className="flex items-end"
                      {...{ [NO_EXPORT_ATTR]: true }}
                    >
                      <CopyButton
                        value={`${carrierUri}/login/${user.activeRegistration.token}`}
                        className="btn-link btn-xs normal-case weight font-normal"
                        noOutline
                        icon={null}
                      >
                        Copy Login
                      </CopyButton>
                    </div>
                  )}
                </div>
              </div>
            ),
          },
          ...(showCarrierCol
            ? [
                {
                  key: 'carrier',
                  label: 'Carrier',
                  render: (user: DTO<AdminCarrierUser>) =>
                    !!user.carrier && (
                      <div className="flex flex-col">
                        <div>
                          <AdminLink
                            to={`/carriers/${user.carrier._id}/overview`}
                          >
                            {user.carrier.name}
                          </AdminLink>
                        </div>
                        <small>{user.carrier._id}</small>
                        <div className="flex justify-between">
                          {user.carrier.code && (
                            <span className="badge badge-sm badge-outline">
                              {user.carrier.code}
                            </span>
                          )}
                        </div>
                      </div>
                    ),
                },
              ]
            : []),
          {
            key: 'accounts',
            label: 'Accounts',
            render: ({ accounts }) => (
              <>
                {(accounts ?? [])
                  .map<React.ReactNode>(a => (
                    <>
                      <AdminLink to={`/accounts/${a?._id}/overview`}>
                        {a?.name}
                      </AdminLink>
                    </>
                  ))
                  .reduce<Array<React.ReactNode>>(
                    (prev, curr) =>
                      prev === null ? [curr] : [...prev, ', ', curr],
                    null,
                  )}
              </>
            ),
          },
          {
            key: 'created',
            label: 'Created',
            render: user => (
              <FvDate utc val={user.created} format="MM/DD/YYYY hh:mm A" />
            ),
          },
          {
            key: 'lastLogin',
            label: 'Last activity',
            sort: (users, dir) =>
              orderBy(
                users,
                u => maxBy(u.sessions, s => s.lastUsed)?.lastUsed,
                dir,
              ),
            render: user => (
              <div className="flex flex-col">
                <FvDate
                  utc
                  val={maxBy(user.sessions, s => s.lastUsed)?.lastUsed}
                  format="MM/DD/YYYY hh:mm A"
                />
                <SessionSliderButton user={user} />
              </div>
            ),
          },
        ]}
      />
    </>
  )
}
